<template>
  <div>
    <p class="text-center">
        {{ trans('api.renew_password') }}
    </p>
    <div class="tab-content mt-4 mb-4">
      <div class="tab-pane fade show active" id="tab_1">
        <div>
          <div class="position-relative" >
            <label for="user" class="mb-2">{{ trans('api.enteruser') }}</label>
            <input
            :placeholder="trans('api.example')+' : '+trans('api.example_username')"
            type="text"
            name="user"
            class="form-control input100 wrap-input100"
            id="user"
            v-validate="{ required: true, regex: /^[A-Za-z0-9]+(?:[ ._-]+[A-Za-z0-9]+)*$/ }"
            data-vv-as=" "
            v-model="user"
            :class="{'form-control': true, 'is-invalid': errors.has('user')}"
            />
            <div class="invalid-feedback">
              <strong style="position: absolute;top: 30px;z-index: 10;width: 100%;text-align: center;">{{ errors.first('user') }}&nbsp;</strong>
            </div>
          </div>
        </div>
        <div>
          <div class="position-relative">
            <label for="password" class="mb-2">{{ trans('api.enternewpassword') }}</label>
            <input
            placeholder="**********"
            type="password"
            name="password"
            class="form-control input100 wrap-input100"
            id="password"
            v-validate="{required: true, min:12,regex: /^.*(?=.{3,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\d\X])(?=.*[\W])^((?!\&|é).)*$.*$/ }"
            data-vv-as=" "
            ref="password"
            :class="{'form-control': true, 'is-invalid': errors.has('password')}"
            />
            <div class="invalid-feedback">
              <strong style="position: absolute;top: 30px;z-index: 10;width: 100%;text-align: center;">{{ errors.first('password') }}&nbsp;</strong>
            </div>
          </div>
          <b-popover triggers="focus" target="password" title="Votre mot de passe doit respecter les criteres minimum suivants" placement='top'>
            <ul>
              <li :class="{'text-danger': inpopup.car}">{{ trans('api.info_password["car"]') }}</li>
              <li :class="{'text-danger': inpopup.min}">{{ trans('api.info_password["min"]') }}</li>
              <li :class="{'text-danger': inpopup.maj}">{{ trans('api.info_password["maj"]') }}</li>
              <li :class="{'text-danger': inpopup.chi}">{{ trans('api.info_password["chi"]') }}</li>
              <li :class="{'text-danger': inpopup.spe}">{{ trans('api.info_password["spe"]') }}</li>
              <li :class="{'text-danger': inpopup.withoutand}">{{ trans('api.info_password["withoutand"]') }}</li>
            </ul>
          </b-popover>
          <div class="position-relative">
            <label for="password_confirmation" class="mb-2">{{ trans('api.enterverifypassword') }}</label>
            <input
            required
            v-validate="'confirmed:password|required|min:12'"
            name="password_confirmation"
            id="password_confirmation"
            type="password"
            class="form-control input100 wrap-input100"
            :class="{'form-control': true, 'is-invalid': errors.has('password_confirmation')}"
            placeholder="**********"
            data-vv-as="password"
            />
            <div class="invalid-feedback">
              <strong style="position: absolute;top: 30px;z-index: 10;width: 100%;text-align: center;">{{ errors.first('password_confirmation') }}&nbsp;</strong>
            </div>
          </div>
        </div>
        <b-button-group class="w-100 mt-3">
          <b-button v-on:click="show_pop" id="sendpwd" variant="primary" style="height: 56px;">{{ trans('api.modify_password') }}</b-button>
        </b-button-group>
      </div>
      <div class="tab-pane fade" id="tab_2">
        <div class="text-center">
          <div v-if="data.msg" class='alert' v-bind:class="'alert-'+[data.type]" >
            {{data.msg}}
          </div>
          <div v-else-if="data.errors" class='alert' v-bind:class="{'alert-danger': data.errors}">
            <span v-if="data.errors.password">
              {{ data.errors.password[0] }}
            </span>
          </div>
          <b-button v-else variant="primary" disabled>
            <b-spinner Large type="grow" style='vertical-align: middle;'></b-spinner>
            <span>{{ trans('api.loading') }}</span>
          </b-button>
          <b-button-group class="w-100 mt-3">
            <b-button v-if='nswitch_tab <= 2' v-on:click="switch_tab(1)" variant="outline-secondary"  style="height: 56px;width: 30px;">{{ trans('pagination.back') }}</b-button>
            <b-button v-if='nswitch_tab === 3' v-on:click="switch_tab(nswitch_tab)" variant="primary sendpwd" style="height: 56px;width: 30px;">{{ trans('api.gotocascad') }}</b-button>
            <b-button v-if='nswitch_tab === 4' v-on:click="switch_tab(nswitch_tab)" variant="primary sendpwd" style="height: 56px;width: 30px;">{{ trans('api.missingpassword') }}</b-button>
          </b-button-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { log } from 'util';
  export default{
    data(){
      return {
        data:[msg=>null,type=>null],
        show:true,
        inpopup:[],
        user:null,
        nswitch_tab:1,
        locale: 'fr',
      };
    },
    mounted:function(){
       $('#sendpwd').prop('disabled', true);
    },
    beforeUpdate:function(){
      if(window.navigator.language){
        if(window.navigator.language === $("html").attr("lang")) {
            this.$validator.localize(window.navigator.language);
          } else {
            this.$validator.localize($("html").attr("lang"));
          }
      } else {
          this.$validator.localize($("html").attr("lang"));
      }
      var password = $('#password')[0].value;
          if(password.length < 12){
            this.inpopup.car = true;
          } else {
            this.inpopup.car = false;
          }
          if(!RegExp('[a-z]').test(password)){
            this.inpopup.min = true;
          } else {
            this.inpopup.min = false;
          }
          if(!RegExp('[A-Z]').test(password)){
            this.inpopup.maj = true;
          } else {
            this.inpopup.maj = false;
          }
          if(!RegExp('[0-9]').test(password)){
            this.inpopup.chi = true;
          } else {
            this.inpopup.chi = false;
          }
          if(!RegExp('\\W').test(password)){
            if(!RegExp('\\&').test(password)){
              this.inpopup.spe = true;
            } else {
              this.inpopup.spe = true;
            }
          } else {
            this.inpopup.spe = false;
          }
          if(!RegExp('\\&').test(password)){
            this.inpopup.withoutand = false;
          } else {
            this.inpopup.withoutand = true;
          }
        if(this.fields.password.invalid){
          $('#sendpwd').prop('disabled', true);
        }
        if(this.fields.user.valid && this.fields.password.valid && this.fields.password_confirmation.valid){
            $('#sendpwd').prop('disabled', false);
        }
    },
    methods:{
      switch_tab:function(x){
        if(x == 1) {
          $('#sendpwd').prop('disabled', true);
          $('#tab_1').tab('show');
          $('#tab_2').collapse('hide').removeClass('active');
        } else if (x == 2){
          $('#tab_1').tab('show');
          $('#tab_2').collapse('hide').removeClass('active');
        } else if (x == 3){
          window.location.replace("https://cascad.ensta.fr/login");
        } else if (x == 4){
          window.location.replace(window.location.protocol + '//' + window.location.hostname + window.location.pathname+"/?missing");
        }
      },
      show_pop:function(){
        $('#tab_1').collapse('hide').removeClass('active');
        $('#tab_2').tab('show');
        this.put_user();
      },
      put_user:function (event) {
        var token = $('#token')[0].value;
        var user = $('#user')[0].value;
        var password = $('#password')[0].value;
        var password_confirmation = $('#password_confirmation')[0].value;
        var lang = $("html").attr("lang");
        this.PUTData('/api/v1/renew', {
          'token': token,
          'user': user,
          'password': password,
          'password_confirmation': password_confirmation,
          'lang': lang,
        })
        .then(data => this.checkstatus(data))
        .catch(error  =>  console.error(error))
      },
      checkstatus:function(data){
        this.data = data.json;
        if(data.status === 200){
          this.show = false;
          $('#user')[0].value = null;
          $('#user')[0].value = null;
          $('#password')[0].value = null;
          $('#password_confirmation')[0].value = null;
          this.nswitch_tab = 3;
        } else if(data.status === 410) {
          this.show = false;
          this.nswitch_tab = 4;
        } else {
          this.show = true;
          this.nswitch_tab = 1;
        }
      },
      PUTData:function(url,data){
        return fetch(url, {
          method: 'PUT',
          body: JSON.stringify(data),
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          redirect: 'follow',
          referrer: 'no-referrer',
        })
        .then(r =>  r.json().then(json => ({status: r.status, json})));
      },
    }
  }
</script>

