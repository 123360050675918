/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

/**
 * IE bootstrap-vue
 */
require('es6-promise').polyfill();
require('isomorphic-fetch');
import 'core-js/stable'
import 'regenerator-runtime/runtime'

/**
 * bootstrap-vue 
 */
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

import VeeValidate from "./vee-validate";


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component('modifier-component', require('./components/modifierComponent.vue').default);
Vue.component('reinitialiser-component', require('./components/reinitialiserComponent.vue').default);
Vue.component('oublier-component', require('./components/oublierComponent.vue').default);
Vue.component('nouveau-component', require('./components/nouveauComponent.vue').default);

// Add trans to vue.js
Vue.prototype.trans = (key) => {
    return _.get(window.trans, key, key);
};

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});

