import Vue from "vue";
import en from 'vee-validate/dist/locale/en';
import fr from 'vee-validate/dist/locale/fr';
import VeeValidate, { Validator } from 'vee-validate';
Vue.use(VeeValidate);
const dictionary = {
  en: {
    custom: {
      courriel:{
          email:"The field must be a valid email address.",
      },
      password:{
          is_not:"Must be different from the old password.",
      },
      password_confirmation:{
          confirmed:"The entry does not match the password.",
          min:"The field must contain at least 12 characters.",
      },
      cguinfo:{
        required:"You must select 'no' or 'yes'.",
        included:"You must validate the IT charter.",
      },
      acc_photo_etab:{
        required:"You must select 'no' or 'yes'.",
      },
      acc_photo_droit:{
        required:"You must select 'no' or 'yes'.",
      },
      acc_photo_public:{
        required:"You must select 'no' or 'yes'.",
      },
      acc_emp_tmp:{
        required:"You must select 'no' or 'yes'.",
      },
    }
  },
  fr: {
    custom: {
      courriel:{
          email:"Le champ doit être une adresse courriel valide.",
      },
      password:{
          is_not:"Doit être différent de l'ancien mot de passe.",
      },
      password_confirmation:{
          confirmed:"La saisie ne correspond pas au mot de passe.",
          min:"Le champ doit contenir au minimum 12 caractères.",
      },
      cguinfo:{
          required:"Vous devez sélectionner 'non' ou 'oui'.",
          included:"Vous devez valider la charte informatique.",
      },
      acc_photo_etab:{
          required:"Vous devez sélectionner 'non' ou 'oui'.",
      },
      acc_photo_droit:{
          required:"Vous devez sélectionner 'non' ou 'oui'.",
      },
      acc_photo_public:{
          required:"Vous devez sélectionner 'non' ou 'oui'.",
      },
      acc_emp_tmp:{
          required:"Vous devez sélectionner 'non' ou 'oui'.",
      },
    }
  }
};
Validator.localize('en', en);
Validator.localize('en', dictionary['en']);
Validator.localize('fr', fr);
Validator.localize('fr', dictionary['fr']);
Vue.use(VeeValidate,{
  locale: 'fr',
});