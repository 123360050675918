<template>
  <div>
    <p class="text-center">
      {{ trans('api.modify_password') }}
    </p>
    <div class="tab-content mt-4 mb-4">
      <div class="tab-pane fade show active" id="tab_1">
        <div class="position-relative">
          <label for="user" class="mb-2">{{ trans('api.enteruser') }}</label>
          <div class="input-group">
            <input
              type="text"
              id="user"
              name="user"
              class="form-control input100 "
              :placeholder="trans('api.example')+' : '+trans('api.example_username')"
              :aria-label="trans('validation.attributes.user')"
              aria-describedby="button-suivant"
              v-validate="{ required: true, regex: /^[A-Za-z0-9]+(?:[ ._-]+[A-Za-z0-9]+)*$/ }"
              data-vv-as=" "
              :class="{'form-control': true, 'is-invalid': errors.has('user')}"
              @keyup.enter="switch_tab(1)"
            >
            <div class="input-group-append">
              <button v-on:click="switch_tab(1)" v-bind:disabled="fields.user && fields.user.invalid" class="btn btn-outline-secondary rounded-right" type="button" id="button-suivant">{{ trans('pagination.next') }}</button>
            </div>
            <div class="invalid-feedback position-absolute">
              <strong style="position: absolute;top: 0px;z-index: 10;width: 78%;text-align: center;">{{ errors.first('user') }}&nbsp;</strong>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="tab_2">
        <div>
          <div class="position-relative" >
            <label for="oldPassword" class="mb-2">{{ trans('api.enteroldpassword') }}</label>
            <input
            placeholder="**********"
            type="password"
            name="oldPassword"
            class="form-control input100 wrap-input100"
            id="oldPassword"
            v-validate="'required'"
            data-vv-as=" "
            v-model="oldPassword"
            :class="{'form-control': true, 'is-invalid': errors.has('oldPassword')}"
            />
            <div class="invalid-feedback">
              <strong style="position: absolute;top: 30px;z-index: 10;width: 100%;text-align: center;">{{ errors.first('oldPassword') }}&nbsp;</strong>
            </div>
          </div>
        </div>
        <div>
          <div class="position-relative">
            <label for="password" class="mb-2">{{ trans('api.enternewpassword') }}</label>
            <input
            placeholder="**********"
            type="password"
            name="password"
            class="form-control input100 wrap-input100"
            id="password"
            v-validate="{ is_not:oldPassword ,required: true, min:12,regex: /^.*(?=.{3,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\d\X])(?=.*[\W])^((?!\&|é).)*$.*$/ }"
            data-vv-as=" "
            ref="password"
            :class="{'form-control': true, 'is-invalid': errors.has('password')}"
            />
            <div class="invalid-feedback">
              <strong style="position: absolute;top: 30px;z-index: 10;width: 100%;text-align: center;">{{ errors.first('password') }}&nbsp;</strong>
            </div>
          </div>
          <b-popover triggers="focus" :show="popshow" target="password" :title="trans('api.info_password[\'title\']')" placement='top'>
            <ul>
              <li :class="{'text-danger': inpopup.dif}">{{ trans('api.info_password["dif"]') }}</li>
              <li :class="{'text-danger': inpopup.car}">{{ trans('api.info_password["car"]') }}</li>
              <li :class="{'text-danger': inpopup.min}">{{ trans('api.info_password["min"]') }}</li>
              <li :class="{'text-danger': inpopup.maj}">{{ trans('api.info_password["maj"]') }}</li>
              <li :class="{'text-danger': inpopup.chi}">{{ trans('api.info_password["chi"]') }}</li>
              <li :class="{'text-danger': inpopup.spe}">{{ trans('api.info_password["spe"]') }}</li>
              <li :class="{'text-danger': inpopup.withoutand}">{{ trans('api.info_password["withoutand"]') }}</li>
            </ul>
          </b-popover>
          <div class="position-relative">
            <label for="password_confirmation" class="mb-2">{{ trans('api.enternewpassword') }}</label>
            <input
            required
            v-validate="'confirmed:password|required|min:12'"
            name="password_confirmation"
            id="password_confirmation"
            type="password"
            class="form-control input100 wrap-input100"
            :class="{'form-control': true, 'is-invalid': errors.has('password_confirmation')}"
            placeholder="**********"
            data-vv-as="password"
            />
            <div class="invalid-feedback">
              <strong style="position: absolute;top: 30px;z-index: 10;width: 100%;text-align: center;">{{ errors.first('password_confirmation') }}&nbsp;</strong>
            </div>
          </div>
        </div>
        <b-button-group class="w-100 mt-3">
          <b-button v-on:click="switch_tab(2)" variant="outline-secondary" style="height: 56px;font-size: 13px;">{{ trans('pagination.back') }}</b-button>
          <b-button v-on:click="show_pop" id="sendpwd" variant="primary" style="height: 56px;font-size: 13px;">{{ trans('api.modify_password') }}</b-button>
        </b-button-group>
      </div>
      <div class="tab-pane fade" id="tab_3">
        <div class="text-center">
          <div v-if="data.msg" class='alert' v-bind:class="'alert-'+[data.type]" >
            {{data.msg}}
          </div>
          <div v-else-if="data.errors" class='alert' v-bind:class="{'alert-danger': data.errors}">
            <span v-if="data.errors.password">
              {{ data.errors.password[0] }}
            </span>
          </div>
          <b-button v-else variant="primary" disabled>
            <b-spinner Large type="grow" style='vertical-align: middle;'></b-spinner>
            <span>{{ trans('api.loading') }}</span>
          </b-button>
          <b-button-group class="w-100 mt-3">
            <b-button v-if='!valid_return' v-on:click="switch_tab(nswitch_tab)" variant="outline-secondary" style="height: 56px;">{{ trans('pagination.back') }}</b-button>
            <b-button v-if='valid_return' v-on:click="switch_tab(4)" variant="primary sendpwd" style="height: 56px;">{{ trans('api.gotocascad') }}</b-button>
          </b-button-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { log } from 'util';
  export default{
    data(){
      return {
        data:[msg=>null,type=>null],
        show:true,
        nswitch_tab:1,
        popshow:false,
        inpopup:[],
        tabIndex: 0,
        oldPassword:null,
        valid_return:null,
        locale: 'fr',
      };
    },
    filters:{
      truncate: function (v) {
        var newline = v.indexOf('\n')
        return newline > 0 ? v.slice(0, newline) : v
      },
      formatDate: function (v) {
        return v.replace(/T|Z/g, ' ')
      }
    },
    beforeUpdate:function(){
        if(window.navigator.language){
          if(window.navigator.language === $("html").attr("lang")) {
              this.$validator.localize(window.navigator.language);
            } else {
              this.$validator.localize($("html").attr("lang"));
            }
        } else {
            this.$validator.localize($("html").attr("lang"));
        }
        if(this.fields.password.invalid){
          this.popshow = true;
          var password = $('#password')[0].value;
          var oldPassword = $('#oldPassword')[0].value;
          if(password == oldPassword){
            this.inpopup.dif = true;
          } else {
            this.inpopup.dif = false;
          }
          if(password.length <= 12){
            this.inpopup.car = true;
          } else {
            this.inpopup.car = false;
          }
          if(!RegExp('[a-z]').test(password)){
            this.inpopup.min = true;
          } else {
            this.inpopup.min = false;
          }
          if(!RegExp('[A-Z]').test(password)){
            this.inpopup.maj = true;
          } else {
            this.inpopup.maj = false;
          }
          if(!RegExp('[0-9]').test(password)){
            this.inpopup.chi = true;
          } else {
            this.inpopup.chi = false;
          }
          if(!RegExp('\\W').test(password)){
              this.inpopup.spe = true;
          } else {
            this.inpopup.spe = false;
          }
          if(!RegExp('\\&|é').test(password)){
            this.inpopup.withoutand = false;
          } else {
            this.inpopup.withoutand = true;
          }
        } else {
          this.popshow =false;
        }
        $('#sendpwd').prop('disabled', true);
        if(this.fields.oldPassword.valid && this.fields.password.valid && this.fields.password_confirmation.valid){
            $('#sendpwd').prop('disabled', false);
        }
    },
    methods:{
      changeLocale:function() {
        this.locale = this.$validator.locale === 'fr' ? 'en' : 'fr';
        this.$validator.localize($("html").attr("lang"));
      },
      switch_tab:function(x){
        if(x == 1) {
          if(this.fields.user.valid){
            $('#tab_1').collapse('hide').removeClass('active');
            $('#tab_2').tab('show');
            $('#tab_3').collapse('hide').removeClass('active');
          }
        } else if (x == 2){
          $('#tab_1').tab('show');
          $('#tab_2').collapse('hide').removeClass('active');
          $('#tab_3').collapse('hide').removeClass('active');
        } else if (x == 4){
          window.location.replace("https://cascad.ensta.fr/login");
        }
      },
      show_pop:function(){
        $('#tab_1').collapse('hide').removeClass('active');
        $('#tab_2').collapse('hide').removeClass('active');
        $('#tab_3').tab('show');
        this.put_user();
      },
      put_user:function (event) {
        var user = $('#user')[0].value;
        var oldPassword = $('#oldPassword')[0].value;
        var password = $('#password')[0].value;
        var password_confirmation = $('#password_confirmation')[0].value;
        var lang = $("html").attr("lang");
        this.PUTData('/api/v1/modify', {
          'user': user,
          'oldPassword': oldPassword,
          'password': password,
          'password_confirmation': password_confirmation,
          'lang': lang,
        })
        .then(data => this.checkstatus(data))
        .catch(error  =>  console.error(error))
      },
      checkstatus:function(data){
        this.data = data.json;
        if(data.status === 200){
          this.show = false;
          $('#user')[0].value = null;
          $('#oldPassword')[0].value = null;
          this.oldPassword = null
          $('#password')[0].value = null;
          $('#password_confirmation')[0].value = null;
          this.nswitch_tab = 2;
          this.valid_return = true;
        } else {
          this.show = true;
          this.nswitch_tab = 1;
          this.valid_return = false;
        }
      },
      PUTData:function(url,data){
        return fetch(url, {
          method: 'PUT',
          body: JSON.stringify(data),
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          redirect: 'follow',
          referrer: 'no-referrer',
        })
        .then(
          r =>  r.json().then(json => ({status: r.status, json}))
        );
      },
    }
  }
</script>

