<template>
    <div>
        <p class="text-center">
          {{ trans('api.missingpassword') }}
        </p>
        <div class="tab-content mt-4 mb-4">
          <div class="tab-pane fade show active" id="tab_1">
            <div class="position-relative mt-3">
              <label for="user" class="mb-2">{{ trans('api.enteruser') }}</label>
              <input
                type="text" 
                id="user" 
                name="user" 
                class="form-control input100 wrap-input100" 
                :placeholder="trans('api.example')+' : '+trans('api.example_username')"
                :aria-label="trans('validation.attributes.user')"
                aria-describedby="button-suivant"
                v-validate="{required : true,  regex: /^[A-Za-z0-9]+(?:[ ._-]+[A-Za-z0-9]+)*$/ }"
                data-vv-as=" "
                :class="{'form-control': true, 'is-invalid': errors.has('user')}"
                @keyup.enter="switch_tab(1)"
              >
               <div class="invalid-feedback">
                <strong style="position: absolute;top: 30px;z-index: 10;width: 100%;text-align: center;">{{ errors.first('user') }}&nbsp;</strong>
              </div>
            </div>
            <div class="position-relative" >
              <label for="courriel" class="mb-2">{{ trans('api.enteremail') }}</label>
              <input
              :placeholder="trans('api.example')+' : '+trans('api.example_mail')"
              type="email"
              name="courriel"
              class="form-control input100 wrap-input100"
              id="courriel"
              v-validate="'required|email'"
              data-vv-as=" "
              :class="{'form-control': true, 'is-invalid': errors.has('courriel')}"
              />
              <div class="invalid-feedback">
                <strong style="position: absolute;top: 30px;z-index: 10;width: 100%;text-align: center;">{{ errors.first('courriel') }}&nbsp;</strong>
              </div>
              <b-popover triggers="hover focus" target="courriel" placement='top'>
                <div class="alert alert-light mt-3 p-0 text-center" role="alert">
                  <label>{{ trans('api.info_missingpassword') }}</label>
                </div>
              </b-popover>
            </div>
            <b-button-group class="w-100 mt-3">
              <b-button v-on:click="show_pop" id="sendpwd" variant="primary" style="height: 56px;">{{ trans('api.sendnewpassword') }}</b-button>
            </b-button-group>
          </div>
          <div class="tab-pane fade" id="tab_2">
            <div class="text-center">
              <div v-if="data.msg" class='alert' v-bind:class="'alert-'+[data.type]" >
                {{data.msg}}
              </div>
              <div v-else-if="data.errors" class='alert' v-bind:class="{'alert-danger': data.errors}">
                <p v-if="data.errors.user">
                  {{ data.errors.user[0] }}
                </p>
                <p v-if="data.errors.courriel">
                  {{ data.errors.courriel[0] }}
                </p>
              </div>
              <b-button v-else variant="primary" disabled>
                <b-spinner Large type="grow" style='vertical-align: middle;'></b-spinner>
                <span>{{ trans('api.loading') }}</span> 
              </b-button>
              <b-button-group class="w-100 mt-3">
                <b-button v-on:click="switch_tab(1)" variant="outline-secondary" style="height: 56px;">{{ trans('pagination.back') }}</b-button>
                <b-button v-if='valid_return' v-on:click="switch_tab(2)" variant="primary sendpwd" style="height: 56px;">{{ trans('api.gotocascad') }}</b-button>
              </b-button-group>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
  export default{
    data(){
      return {
        data:[msg=>null,type=>null,errors=>null],
        message:null,
        valid_return:null,
        locale: 'fr',
      };
    },
    beforeUpdate:function(){
      if(window.navigator.language){
        if(window.navigator.language === $("html").attr("lang")) {
            this.$validator.localize(window.navigator.language);
          } else {
            this.$validator.localize($("html").attr("lang"));
          }
      } else {
          this.$validator.localize($("html").attr("lang"));
      }
      $('#sendpwd').prop('disabled', true);
      if(this.fields.user.valid && this.fields.courriel.valid){
        $('#sendpwd').prop('disabled', false);
      }
    },
    methods:{
      switch_tab:function(x){
        if(x == 1) {
          this.data = [msg=>null,type=>null,errors=>null];
          this.user = null;
          this.courriel = null;
          $('#sendpwd').prop('disabled', true);
          $('#tab_1').tab('show');
          $('#tab_2').collapse('hide').removeClass('active')
        } else if (x == 2){
          window.location.replace("https://cascad.ensta.fr/login");
        }
      },
      show_pop:function(){
        $('#tab_1').collapse('hide').removeClass('active');
        $('#tab_2').tab('show');
        this.post_user();
      },
      post_user:function (event) {
        this.sending=1;
        this.user='';this.message=null;
        var user = $('#user')[0].value;
        var courriel = $('#courriel')[0].value;
        var lang = $("html").attr("lang");
        this.POSTData('/api/v1/reset_password', {
          'user': user,
          'courriel': courriel,
          'lang': lang,
        })
        .then(data    => this.checkstatus(data))
        .catch(error  => console.error(error))
      },
      checkstatus:function(data){
        this.data = data.json;
        if(data.status === 200){
          $('#sendpwd').prop('disabled', true);
          $('#user')[0].value = null;
          $('#courriel')[0].value  = null;
          this.valid_return = true;
        } else {
          this.valid_return = false;
        }
      },
      POSTData:function(url,data){
        return fetch(url, {
          method: 'POST',
          body: JSON.stringify(data),
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          redirect: 'follow',
          referrer: 'no-referrer',
        })
        .then(r =>  r.json().then(json => ({status: r.status, json})))
      },
    }
  }
</script>

