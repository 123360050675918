<template>
  <div>
    <p class="text-center">
        {{ trans('api.new_password') }}
    </p>
    <div class="tab-content mt-4 mb-4">
      <div class="text-center" >
        <div class="radio">
          <form :hidden="valide_cgu" novalidate @submit.prevent="validateForm('form-1')" data-vv-scope="form-1">
            <div class="fade show card w-100 m-0 mb-5 position-relative font-weight-normal"
              :class="{'border-danger': errors.has('.form-1cguinfo')}">
              <div class="card-header bg-transparent">
                {{ trans('api.cguinfo_title') }}
              </div>
              <div class="card-body">
                <button class="btn btn-outline-secondary mb-2 dl-chart" v-on:click="dl(trans('api.lang'))" style="height: 56px;cursor: pointer;" :href="'/dl/charte_'+trans('api.lang')+'.pdf'" :class="{'border-danger text-danger': errors.has('form-1.cguinfo') }">{{trans('api.downloadchart')}}</button>
                <p class="card-text">{{ trans('api.cguinfo') }}</p>
                <p v-show="errors.has('form-1.cguinfo')" class="text-danger"></p>
                <b-popover :show="errors.has('form-1.cguinfo')" target="div_cguinfo" placement='top'>
                  <div class="alert alert-light mt-3 p-0 text-center" role="alert">
                    <label>{{ errors.first('form-1.cguinfo') }}</label>
                  </div>
                </b-popover>
                <input name="cguinfo" value="no" type="radio" id="cguinfo_no">
                <input name="cguinfo" value="yes" type="radio" id="cguinfo_yes" v-validate="'required|included:yes'" >
                <div id="div_cguinfo" class="switch" :class="{'errorcheck': errors.has('form-1.cguinfo')}">
                  <label onclick="event.stopPropagation();" for="cguinfo_no">{{ trans('api.no') }}</label>
                  <label onclick="event.stopPropagation();" for="cguinfo_yes">{{ trans('api.yes') }}</label>
                  <span></span>
                </div>
              </div>
            </div>
            <p class="control">
              <button type="submit" style="height: 56px;" class="btn btn-outline-secondary" name="button"> {{ trans('pagination.next') }}</button>
            </p>
          </form>
          <form :hidden="!valide_cgu || valide_photo" novalidate @submit.prevent="validateForm('form-2')" data-vv-scope="form-2">
            <div class="fade show card w-100 m-0 mb-5 position-relative font-weight-normal"
              v-bind:class="{
                'border-danger': errors.has('form-2.acc_photo_etab') ||
                errors.has('form-2.acc_photo_droit')||
                errors.has('form-2.acc_photo_public')
              }">
              <div class="card-header bg-transparent">
              {{ trans('api.acc_photo_etab_title') }}
              </div>
              <div class="card-body">
                <p class="card-text">{{ trans('api.acc_photo_etab') }}</p>
                <b-popover :show="errors.has('form-2.acc_photo_etab')" target="div_acc_photo_etab" placement='top'>
                  <div class="alert alert-light mt-3 p-0 text-center" role="alert">
                    <label>{{ errors.first('form-2.acc_photo_etab') }}</label>
                  </div>
                </b-popover>
                <input name="acc_photo_etab" value="no" type="radio" id="acc_photo_etab_no">
                <input name="acc_photo_etab" value="yes" type="radio" id="acc_photo_etab_yes" v-validate="'required|included:yes,no'" >
                <div id="div_acc_photo_etab" class="switch" :class="{'errorcheck': errors.has('form-2.acc_photo_etab')}">
                  <label onclick="event.stopPropagation();" for="acc_photo_etab_no">{{ trans('api.no') }}</label>
                  <label onclick="event.stopPropagation();" for="acc_photo_etab_yes">{{ trans('api.yes') }}</label>
                  <span></span>
                </div>
              </div>
              <hr class="m-0">
              <div class="card-body">
                <p class="card-text">{{ trans('api.acc_photo_droit') }}</p>
                <b-popover :show="errors.has('form-2.acc_photo_droit')" target="div_acc_photo_droit" placement='top'>
                  <div class="alert alert-light mt-3 p-0 text-center" role="alert">
                    <label>{{ errors.first('form-2.acc_photo_droit') }}</label>
                  </div>
                </b-popover>
                <input name="acc_photo_droit" value="no" type="radio" id="acc_photo_droit_no">
                <input name="acc_photo_droit" value="yes" type="radio" id="acc_photo_droit_yes" v-validate="'required|included:yes,no'" >
                <div id="div_acc_photo_droit" class="switch" :class="{'errorcheck': errors.has('form-2.acc_photo_droit')}">
                  <label onclick="event.stopPropagation();" for="acc_photo_droit_no">{{ trans('api.no') }}</label>
                  <label onclick="event.stopPropagation();" for="acc_photo_droit_yes">{{ trans('api.yes') }}</label>
                  <span></span>
                </div>
              </div>
              <hr class="m-0">
              <div class="card-body">
                <p class="card-text">{{ trans('api.acc_photo_public') }}</p>
                <b-popover :show="errors.has('form-2.acc_photo_public')" target="div_acc_photo_public" placement='top'>
                  <div class="alert alert-light mt-3 p-0 text-center" role="alert">
                    <label>{{ errors.first('form-2.acc_photo_public') }}</label>
                  </div>
                </b-popover>
                <input name="acc_photo_public" value="no" type="radio" id="acc_photo_public_no">
                <input name="acc_photo_public" value="yes" type="radio" id="acc_photo_public_yes" v-validate="'required|included:yes,no'" >
                <div id="div_acc_photo_public" class="switch" :class="{'errorcheck': errors.has('form-2.acc_photo_public')}">
                  <label onclick="event.stopPropagation();" for="acc_photo_public_no">{{ trans('api.no') }}</label>
                  <label onclick="event.stopPropagation();" for="acc_photo_public_yes">{{ trans('api.yes') }}</label>
                  <span></span>
                </div>
              </div>
            </div>
            <div class="fade show card w-100 m-0 mb-5 position-relative font-weight-normal"
              :class="{'border-danger': errors.has('form-2.acc_emp_tmp')}">
              <div class="card-header bg-transparent">
              {{ trans('api.acc_emp_tmp_title') }}
              </div>
              <div class="card-body">
                <p class="card-text"> {{ trans('api.acc_emp_tmp') }}</p>
                <b-popover :show="errors.has('form-2.acc_emp_tmp')" target="div_acc_emp_tmp" placement='top'>
                  <div class="alert alert-light mt-3 p-0 text-center" role="alert">
                    <label>{{ errors.first('form-2.acc_emp_tmp') }}</label>
                  </div>
                </b-popover>
                <input name="acc_emp_tmp" value="no" type="radio" id="acc_emp_tmp_no">
                <input name="acc_emp_tmp" value="yes" type="radio" id="acc_emp_tmp_yes" v-validate="'required|included:yes,no'" >
                <div id="div_acc_emp_tmp" class="switch" :class="{'errorcheck': errors.has('form-2.acc_emp_tmp')}">
                  <label onclick="event.stopPropagation();" for="acc_emp_tmp_no">{{ trans('api.no') }}</label>
                  <label onclick="event.stopPropagation();" for="acc_emp_tmp_yes">{{ trans('api.yes') }}</label>
                  <span></span>
                </div>
              </div>
            </div>
            <div class="input-group-append d-block">
              <p class="control btn-group">
                <button type="button" v-on:click="valide_cgu = false" style="height: 56px;" class="btn btn-outline-secondary"> {{ trans('pagination.previous') }}</button>
                <button type="submit" style="height: 56px;" class="btn btn-outline-secondary" name="button"> {{ trans('pagination.next') }}</button>
              </p>
            </div>
          </form>
        </div>
      </div>
      <div :hidden="!valide_photo" class="tab-pane fade show active" id="tab_1">
        <div>
          <div class="position-relative">
            <label for="user" class="mb-2">{{ trans('api.user') }}</label>
            <input
            :placeholder="trans('api.example')+' : '+trans('api.example_username')"
            type="text"
            name="user"
            class="form-control input100 wrap-input100"
            id="user"
            v-validate="{ required: true, regex: /^[A-Za-z0-9]+(?:[ ._-]+[A-Za-z0-9]+)*$/ }"
            data-vv-as=" "
            v-model="user"
            :class="{'form-control': true, 'is-invalid': errors.has('user')}"
            disabled
            />
            <div class="invalid-feedback">
              <strong style="position: absolute;top: 30px;z-index: 10;width: 100%;text-align: center;">{{ errors.first('user') }}&nbsp;</strong>
            </div>
          </div>
        </div>
        <div v-if="m">
          <div class="position-relative">
            <b-popover triggers="hover" target="emergencyaddressinfo" v-bind:title='trans("api.info_emergencyaddress[\"title\"]")' placement='top'>
              {{trans('api.info_emergencyaddress["text"]')}}
            </b-popover>
            <label for="emergencyaddress" class="mb-2">
              {{ trans('api.emergencyaddress') }}

              <small id="emergencyaddressinfo"
              class="badge badge-primary badge-pill"
              style="background-color: #1d416d;font-size: 10px; height: 13px; width: 13px;position: relative;top: 3px;left: 1px;"
              >
                <span
                style="position: absolute;right: 4px;top: 2px;"
                >
                ?
                </span>
              </small>
            </label>
            <input
            :placeholder="''"
            type="text"
            name="emergencyaddress"
            class="form-control input100 wrap-input100"
            id="emergencyaddress"
            v-validate="{ required: true, email }"
            data-vv-as=" "
            v-model="emergencyaddress"
            :class="{'form-control': true, 'is-invalid': errors.has('emergencyaddress')}"
            />
            <div class="invalid-feedback">
              <strong style="position: absolute;top: 30px;z-index: 10;width: 100%;text-align: center;">{{ errors.first('emergencyaddress') }}&nbsp;</strong>
            </div>
          </div>
        </div>
        <div>
          <div class="position-relative">
            <label for="password" class="mb-2">{{ trans('api.enterpassword') }}</label>
            <input
            placeholder="**********"
            type="password"
            name="password"
            class="form-control input100 wrap-input100"
            id="password"
            v-validate="{required: true, min:12,regex: /^.*(?=.{3,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\d\X])(?=.*[\W])^((?!\&|é).)*$.*$/ }"
            data-vv-as=" "
            ref="password"
            :class="{'form-control': true, 'is-invalid': errors.has('password')}"
            />
            <div class="invalid-feedback">
              <strong style="position: absolute;top: 30px;z-index: 10;width: 100%;text-align: center;">{{ errors.first('password') }}&nbsp;</strong>
            </div>
          </div>
          <b-popover triggers="focus" target="password" v-bind:title='trans("api.info_password[\"title\"]")' placement='top'>
            <ul>
              <li :class="{'text-danger': inpopup.car}">{{ trans('api.info_password["car"]') }}</li>
              <li :class="{'text-danger': inpopup.min}">{{ trans('api.info_password["min"]') }}</li>
              <li :class="{'text-danger': inpopup.maj}">{{ trans('api.info_password["maj"]') }}</li>
              <li :class="{'text-danger': inpopup.chi}">{{ trans('api.info_password["chi"]') }}</li>
              <li :class="{'text-danger': inpopup.spe}">{{ trans('api.info_password["spe"]') }}</li>
              <li :class="{'text-danger': inpopup.withoutand}">{{ trans('api.info_password["withoutand"]') }}</li>
            </ul>
          </b-popover>
          <div class="position-relative">
            <label for="password_confirmation" class="mb-2">{{ trans('api.enterverifypassword') }}</label>
            <input
            required
            v-validate="'confirmed:password|required|min:12'"
            name="password_confirmation"
            id="password_confirmation"
            type="password"
            class="form-control input100 wrap-input100"
            :class="{'form-control': true, 'is-invalid': errors.has('password_confirmation')}"
            placeholder="**********"
            data-vv-as="password"
            />
            <div class="invalid-feedback">
              <strong style="position: absolute;top: 30px;z-index: 10;width: 100%;text-align: center;">{{ errors.first('password_confirmation') }}&nbsp;</strong>
            </div>
          </div>
        </div>
        <b-button-group class="w-100 mt-3">
          <button type="button" v-on:click="valide_photo = false" style="height: 56px;" class="btn btn-outline-secondary"> {{ trans('pagination.previous') }}</button>
          <b-button v-on:click="show_pop" id="sendpwd" variant="primary" style="height: 56px;">{{ trans('api.save_password') }}</b-button>
        </b-button-group>
      </div>
      <div class="tab-pane fade" id="tab_2">
        <div class="text-center">
          <div v-if="data.message" class='alert' v-bind:class="'alert-'+[data.type]" >
            {{data.message}}
          </div>
          <div v-else-if="data.errors" class='alert' v-bind:class="{'alert-danger': data.errors}">
            <span v-if="data.errors.password">
              {{ data.errors.password[0] }}
            </span>
          </div>
          <b-button v-else variant="primary" disabled>
            <b-spinner Large type="grow" style='vertical-align: middle;'></b-spinner>
            <span>{{ trans('api.loading') }}</span>
          </b-button>
          <b-button-group class="w-100 mt-3">
            <b-button v-if='nswitch_tab <= 2' v-on:click="switch_tab(1)" variant="outline-secondary"  style="height: 56px;width: 30px;">{{ trans('pagination.back') }}</b-button>
            <b-button v-if='nswitch_tab === 3' v-on:click="switch_tab(nswitch_tab)" variant="primary sendpwd" style="height: 56px;width: 30px;">{{ trans('api.gotosynapses') }}</b-button>
            <b-button v-if='nswitch_tab === 4' v-on:click="switch_tab(nswitch_tab)" variant="primary sendpwd" style="height: 56px;width: 30px;">{{ trans('api.missingpassword') }}</b-button>
          </b-button-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { log } from 'util';
  export default{
    data(){
      return {
        m:null,
        user:null,
        email:null,
        emergencyaddress:null,
        data:[message=>null,type=>null],
        show:true,
        inpopup:[],
        nswitch_tab:1,
        locale: 'fr',
        valide_cgu: false,
        valide_photo: false,
        selected_value:null,
        model: {
          bsigncharte: '',
          bpublidroitimage: ''
        }
      };
    },
    mounted:function(){
       $('#sendpwd').prop('disabled', true);
    },
    beforeUpdate:function(){
      this.user = $('#user')[0].value;
      if(window.navigator.language){
        if(window.navigator.language === $("html").attr("lang")) {
            this.$validator.localize(window.navigator.language);
          } else {
            this.$validator.localize($("html").attr("lang"));
          }
      } else {
          this.$validator.localize($("html").attr("lang"));
      }
      var password = $('#password')[0].value;
      if(password.length < 12){
        this.inpopup.car = true;
      } else {
        this.inpopup.car = false;
      }
      if(!RegExp('[a-z]').test(password)){
        this.inpopup.min = true;
      } else {
        this.inpopup.min = false;
      }
      if(!RegExp('[A-Z]').test(password)){
        this.inpopup.maj = true;
      } else {
        this.inpopup.maj = false;
      }
      if(!RegExp('[0-9]').test(password)){
        this.inpopup.chi = true;
      } else {
        this.inpopup.chi = false;
      }
      if(!RegExp('\\W').test(password)){
          this.inpopup.spe = true;
      } else {
        this.inpopup.spe = false;
      }
      if(!RegExp('\\&|é').test(password)){
        this.inpopup.withoutand = false;
      } else {
        this.inpopup.withoutand = true;
      }
      if(this.fields.password.invalid){
        $('#sendpwd').prop('disabled', true);
      }
      if(this.fields.user.valid && this.fields.password.valid && this.fields.password_confirmation.valid){
          $('#sendpwd').prop('disabled', false);
      }
      this.checkemergencymail();
    },
    methods:{
      validateForm(scope) {
        this.$validator.validateAll(scope).then((result) => {
          if (result) {
            if(scope === 'form-1'){
              this.valide_cgu = true;
            }
            if(scope === 'form-2'){
              this.valide_photo = true;
              this.selected_value = {
                "bsigncharte" : $('input[name=cguinfo]:checked').val(),
                "bpubliphotointranet" : $('input[name=acc_photo_etab]:checked').val(),
                "bpublidroitimage" : $('input[name=acc_photo_droit]:checked').val(),
                "bpubliphotosite" : $('input[name=acc_photo_public]:checked').val(),
                "bvisuedtintranet" : $('input[name=acc_emp_tmp]:checked').val(),
              }
            }
          }
        });
      },
      dl(lang){
        window.open(
          '/dl/charte_'+lang+'.pdf',
          '_blank'
        )
      },
      switch_tab:function(x){
        if(x == 1) {
          $('#sendpwd').prop('disabled', true);
          $('#tab_1').tab('show');
          $('#tab_2').collapse('hide').removeClass('active');
        } else if (x == 2){
          $('#tab_1').tab('show');
          $('#tab_2').collapse('hide').removeClass('active');
        } else if (x == 3){
          window.location.replace("https://synapses.ensta-paris.fr/login?type=Shib");
        } else if (x == 4){
          window.location.replace(window.location.protocol + '//' + window.location.hostname + window.location.pathname+"/?missing");
        }
      },
      show_pop:function(){
        $('#tab_1').collapse('hide').removeClass('active');
        $('#tab_2').tab('show');
        this.put_user();
      },
      put_user:function (event) {
        var token = $('#token')[0].value;
        var user = $('#user')[0].value;
        var password = $('#password')[0].value;
        var password_confirmation = $('#password_confirmation')[0].value;
        var lang = $("html").attr("lang");
        if($('#emergencyaddress').length){
          var emergencyaddress = $('#emergencyaddress')[0].value;
        } else {
          var emergencyaddress = null;
        }
        this.PUTData('/api/v1/new', {
          'token': token,
          'user': user,
          'password': password,
          'password_confirmation': password_confirmation,
          'lang': lang,
          'emergencyaddress': emergencyaddress,
          'selected_value': this.selected_value,
        })
        .then(data => this.checkstatus(data))
        .catch(error  =>  console.error(error))
      },
      checkstatus:function(data){
        this.data = data.json;
        if(data.status === 200){
          this.show = false;
          $('#user')[0].value = null;
          $('#user')[0].value = null;
          $('#password')[0].value = null;
          $('#password_confirmation')[0].value = null;
          this.nswitch_tab = 3;
        } else if(data.status === 410) {
          this.show = false;
          this.nswitch_tab = 4;
        } else {
          this.show = true;
          this.nswitch_tab = 1;
        }
      },
      checkemergencymail:function(){
        var url_string = window.location.href;
        var url = new URL(url_string);
        this.m = url.searchParams.get("m");
      },
      PUTData:function(url,data){
        return fetch(url, {
          method: 'PUT',
          body: JSON.stringify(data),
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          redirect: 'follow',
          referrer: 'no-referrer',
        })
        .then(r =>  r.json().then(json => ({status: r.status, json})));
      },
    }
  }
</script>
<style>
  legend{
    font-size: 14px !important;
  }
  .h-50px{
    height: 50px;
  }
  .switch label {
    text-transform: capitalize;
  }
  .card{cursor: auto;}
  .switch {
    position: relative;
    /* top: 50%; */
    bottom: -9px;
    left: 50%;
    width: 150px;
    height: 50px;
    text-align: center;
    margin: 0px 0 0 -75px;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border-radius: 25px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    /* background-color: #f7f7f7; */
    /* background: #4cd964; */
  }
  .switch span {
    position: absolute;
    width: 20px;
    height: 4px;
    top: 50%;
    left: 50%;
    margin: -2px 0px 0px -4px;
    /* background: #fff; */
    display: block;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border-radius: 2px;
  }
  .switch span:after {
    content: "";
    display: block;
    position: absolute;
    width: 4px;
    height: 12px;
    margin-top: -8px;
    /* background: #fff; */
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border-radius: 2px;
  }
  input[type=radio] {
    display: none;
  }
  .switch label {
    cursor: pointer;
    /* color: rgba(0,0,0,0.8); */
    width: 72px;
    line-height: 50px;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    top: -1px;
    position: relative;
  }
  label[for=no] {
    position: absolute;
    left: 0px;
    height: 20px;
  }
  label[for=yes] {
    position: absolute;
    right: 0px;
  }
  .errorcheck{
    /* background: #fff; */
    background: transparent
  }
  .errorcheck>label{
    color:#ff3b30;
  }
  .errorcheck span {
    background: #ff3b30;
    margin-left: -8px;
  }
  .errorcheck span:after {
    background: #ff3b30;
    height: 20px;
    margin-top: -8px;
    margin-left: 8px;
  }

  #cguinfo_no:checked ~ .switch {
    color: #ff3b30;
  }
  #cguinfo_yes:checked ~ .switch {
    color: #4cd964;
  }
  #cguinfo_no:checked ~ .switch span {
    background: #ff3b30;
    margin-left: -8px;
  }
  #cguinfo_no:checked ~ .switch span:after {
    background: #ff3b30;
    height: 20px;
    margin-top: -8px;
    margin-left: 8px;
  }
  #cguinfo_yes:checked ~ .switch span,
  #cguinfo_yes:checked ~ .switch span:after,
  #acc_photo_etab_no:checked ~ .switch span,
  #acc_photo_etab_no:checked ~ .switch span:after,
  #acc_photo_etab_yes:checked ~ .switch span,
  #acc_photo_etab_yes:checked ~ .switch span:after,
  #acc_photo_droit_no:checked ~ .switch span,
  #acc_photo_droit_no:checked ~ .switch span:after,
  #acc_photo_droit_yes:checked ~ .switch span,
  #acc_photo_droit_yes:checked ~ .switch span:after,
  #acc_photo_public_no:checked ~ .switch span,
  #acc_photo_public_no:checked ~ .switch span:after,
  #acc_photo_public_yes:checked ~ .switch span,
  #acc_photo_public_yes:checked ~ .switch span:after,
  #acc_emp_tmp_no:checked ~ .switch span,
  #acc_emp_tmp_no:checked ~ .switch span:after,
  #acc_emp_tmp_yes:checked ~ .switch span,
  #acc_emp_tmp_yes:checked ~ .switch span:after
  {
    background: #4cd964;
  }
  #cguinfo_yes:checked ~ .switch label[for=cguinfo_yes],
  #acc_photo_etab_yes:checked ~ .switch label[for=acc_photo_etab_yes],
  #acc_photo_etab_no:checked ~ .switch label[for=acc_photo_etab_no],
  #acc_photo_droit_yes:checked ~ .switch label[for=acc_photo_droit_yes],
  #acc_photo_droit_no:checked ~ .switch label[for=acc_photo_droit_no],
  #acc_photo_public_yes:checked ~ .switch label[for=acc_photo_public_yes],
  #acc_photo_public_no:checked ~ .switch label[for=acc_photo_public_no],
  #acc_emp_tmp_yes:checked ~ .switch label[for=acc_emp_tmp_yes],
  #acc_emp_tmp_no:checked ~ .switch label[for=acc_emp_tmp_no]
  {
    color: #4cd964;
  }
  #cguinfo_no:checked ~ .switch label[for=cguinfo_no] {
    color: #ff3b30;
  }
  #cguinfo_yes:checked ~ .switch label[for=cguinfo_no],
  #cguinfo_no:checked ~ .switch label[for=cguinfo_yes],
  #acc_photo_etab_yes:checked ~ .switch label[for=acc_photo_etab_no],
  #acc_photo_etab_no:checked ~ .switch label[for=acc_photo_etab_yes],
  #acc_photo_droit_yes:checked ~ .switch label[for=acc_photo_droit_no],
  #acc_photo_droit_no:checked ~ .switch label[for=acc_photo_droit_yes],
  #acc_photo_public_yes:checked ~ .switch label[for=acc_photo_public_no],
  #acc_photo_public_no:checked ~ .switch label[for=acc_photo_public_yes],
  #acc_emp_tmp_yes:checked ~ .switch label[for=acc_emp_tmp_no],
  #acc_emp_tmp_no:checked ~ .switch label[for=acc_emp_tmp_yes]
  {
    color: rgba(0,0,0,0.2);
  }
</style>

